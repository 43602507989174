<template>
  <div id="page-reseller">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="person" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.reseller.title') }}
      </h3>
      <div>
        <router-link
            class="btn btn-info mt-2"
            :to="{ name: 'reseller-create' }"
        >
          {{ $t('pages.reseller.createReseller') }}
        </router-link>
      </div>
    </div>

    <hr />

    <div class="d-xl-flex justify-content-between mb-4">
      <div class="d-xl-flex mb-4 w-100">
        <div class="search mt-2 mt-md-0">
          <div class="input-group">
            <input
                type="text"
                class="form-control border"
                v-model="filter.search"
                :placeholder="$t('common.search.keyword')"
                v-on:keyup.enter="sendResearchRequest"
            >
            <b-icon class="icon-x" icon="x" font-scale="1.5" v-on:click="sendResearchRequestReset"></b-icon>
            <div class="input-group-append">
              <button class="btn btn-success p-0 pl-2 pr-2" v-on:click="sendResearchRequest">
                {{ $t('common.search.research') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow">
      <div class="card-body p-0">
        <TableComponent
            v-if="ResellerModule.resellers.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="ResellerModule.resellers"
            :entityName="'user'"
            :actionList="configTableComponent.actionList"
            :isAction="true"
            @ordered="clickOrdered"
            @switch="handleSwitch"
            @specific-action="handleSpecificAction"
            @specific-unique-action="handleSpecificUniqueAction"
        />

        <div v-if="ResellerModule.resellers.length === 0">
          <div v-if="filter.search != null" class="text-center mt-5">
            {{ $t('common.search.noResult') }}
          </div>
          <div v-else class="text-center mt-5">
            {{ $t('pages.reseller.noResultList') }}
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="filter-elem-per-page">
              <select class="form-control" v-model="filter.elemPerPage" v-on:change="changeLimitList">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div class="count-elem my-auto ml-3">
              <b>{{ (filter.search) ? ResellerModule.totalRequest :  ResellerModule.total}}</b>
              {{ $t('common.result') }}
            </div>
          </div>

          <div class="d-flex">
            <vPaginate
                v-if="ResellerModule.resellers.length > 0"
                class="my-auto"
                :classes="bootstrapPaginationClasses"
                v-model="filter.page"
                :page-count="
                  (ResellerModule.totalRequest > 0)
                  ? Math.ceil(ResellerModule.totalRequest / filter.elemPerPage)
                  : Math.ceil(ResellerModule.total / filter.elemPerPage)
                "
                @change="sendPaginateRequest"
            >
            </vPaginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 25,
        order: {
          index: 'id',
          order: 'DESC'
        },
        search: null,
      },
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'left', 'filter': true, 'order': 'DESC' },
          {'index' : 'username', 'indexTrad' : 'pages.reseller.tableList.mailReseller', 'align' : 'left', 'filter': true },
          {'index' : 'isProspect', 'indexTrad' : 'pages.user.prospect', 'align' : 'center', 'filter': false, 'boolean': true },
          {'index' : 'firstname', 'indexTrad' : 'common.firstname', 'align' : 'center', 'filter': true },
          {'index' : 'lastname', 'indexTrad' : 'common.lastname', 'align' : 'center', 'filter': true },
          {'index' : 'company', 'indexTrad' : 'common.company', 'align' : 'center', 'filter': false },
          {'index' : 'discount', 'indexTrad' : 'pages.reseller.tableList.discount', 'align' : 'center', 'filter': false },
          {'index' : 'api_token', 'indexTrad' : 'Api Token', 'align' : 'left', 'filter': true, 'copy' : true },
          {'index' : 'type', 'indexTrad' : 'Type', 'align' : 'left', 'filter': true, 'date': true },
          {'index' : 'isActive', 'indexTrad' : 'common.active', 'align' : 'center', 'filter': false, 'switch': true }
        ],
        actionList: [
          {
            'indexTrad' : 'common.actions.see',
            'routeName' : 'reseller-show',
            'icon' : 'eye',
            'icon-variant': 'success',
            'params' : true,
            'link-action': true
          },
          {
            'indexTrad': 'common.actions.edit',
            'routeName' : 'reseller-edit',
            'icon' : 'pencil-square',
            'icon-variant': 'info',
            'params' : true ,
            'link-action': true
          },
          {
            'index': 'delete',
            'indexTrad' : 'common.actions.delete',
            'icon' : 'trash',
            'icon-variant': 'danger',
            'isAdminOnly': true,
            'isSpecificAction' : true
          },
          {
            'index': 'connectAs',
            'indexTrad' : 'common.actions.connectAs',
            'icon' : 'person-bounding-box',
            'icon-variant': 'dark',
            'isAdminOnly': true,
            'isSpecificUniqueAction' : true
          },
        ],
      }
    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchResellers', {
      perPage: this.filter.elemPerPage,
      page: this.filter.page,
      order: this.filter.order
    })
      .then(() => {
        this.hideLoader()
      })
      .catch(() => {
        this.hideLoader()
      })

  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    handleSpecificUniqueAction(obj) {
      if(obj.action === 'connectAs') {
        this.connectAsUser(obj)
      }
    },
    connectAsUser(obj) {
      let reseller = this.$store.getters.getResellerById(obj.id)

      this.$swal({
        title: this.$t('pages.reseller.connectAs.title') + reseller.username +' ?',
        text: this.$t('pages.reseller.connectAs.text') + reseller.username,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('pages.reseller.connectAs.title') + reseller.username
      }).then((result) => {
        if(result.value) {
          this.$store.dispatch('connectAs', {adminId: this.$store.getters.getCurrentUser.id, id: obj.id})
        }
      })
    },
    /*----------------- SELECT LIMIT LIST ------------------*/
    changeLimitList() {
      this.showLoader()

      this.$store.dispatch('fetchResellers', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null
      })
        .then(() => {
          this.hideLoader()
        })
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.showLoader()

      // Reset
      this.filter.search = null

      this.$store.dispatch('fetchResellers', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /**
     * Send request for research
     */
    sendResearchRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchResellers', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchResellers', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- ORDER ------------------*/
    /**
     * Send request for order
     * @param order
     */
    clickOrdered(order)
    {
      this.showLoader()

      this.filter.order = order

      this.$store.dispatch('fetchResellers', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SWITCH / SPECIFIC ACTION ------------------*/
    /**
     * Handle switch (emit from table component)
     */
    handleSwitch(obj) {
      // Get reseller
      let reseller = this.$store.getters.getResellerById(obj.id)

      // Security
      if(!reseller) {
        this.$bvToast.toast(this.$t('pages.reseller.notFound'), {
          title: this.$t('common.error') + ' !',
          variant: 'danger',
          solid: true
        })
      }

      // Update is Active
      reseller.isActive = obj.value

      // Loader
      this.showLoader()

      // Call API
      this.$store.dispatch('updateIsActiveReseller', { id: reseller.id, reseller: reseller })
          .then(() => {
            this.hideLoader()

            this.$bvToast.toast(
              (reseller.isActive)
                  ? this.$t('pages.reseller.toast.resellerActive')
                  : this.$t('pages.reseller.toast.resellerDisabled') ,
              {
                        title: this.$t('pages.reseller.editSuccess'),
                        variant: (reseller.isActive) ? 'success' : 'warning',
                        solid: true
                    }
            )
          })
          .catch(() => {
            this.hideLoader()
          })
    },
    /**
     * handle specific action (emit from table component)
     *
     * @param obj
     */
    handleSpecificAction(obj) {

      // Delete
      if(obj.action === 'delete') {
        this.$swal({
          title: this.$t('pages.reseller.toast.wantDelete'),
          text: this.$t('common.actionIrreversible'),
          showCancelButton: true,
          confirmButtonColor: '#fa5c7c',
          cancelButtonColor: '#929292',
          confirmButtonText: 'Supprimer'
        }).then((result) => {
          if (result.value) {
            this.showLoader()
            this.$store.dispatch('deleteReseller', {id: obj.id})
                .then(() => {
                  this.$bvToast.toast(this.$t('pages.reseller.toast.deleteSuccess'), {
                    title: this.$t('pages.reseller.toast.delete'),
                    variant: 'success',
                    solid: true
                  })

                  this.$store.dispatch('fetchResellers', {
                    perPage: this.filter.elemPerPage,
                    page: 1
                  })

                  this.hideLoader()
                })
                .catch(() => {
                  this.hideLoader()

                  this.$bvToast.toast(this.$t('pages.reseller.toast.errorDelete'), {
                    title: this.$t('pages.reseller.toast.errorDeleteTitle'),
                    variant: 'warning',
                    solid: true
                  })
                })
          }
        });
      }
    }
  },
  computed: {
    ...mapState(['ResellerModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent'),
    vPaginate: () => import('vue-plain-pagination')
  }
}
</script>

<style scoped>

</style>
