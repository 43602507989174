<template>
  <div id="page-reseller-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ $t('pages.reseller.createReseller') }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'reseller-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudReseller
        :reseller="reseller"
        :actionForm="'create'"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      reseller: {
        isActive: true,
        isProspect: true,
        roles: ["ROLE_RESELLER"]
      }
    }
  },
  components : {
    FormCrudReseller: () => import('@/components/pages/reseller/FormCrudReseller')
  }
}
</script>

<style scoped>

</style>
