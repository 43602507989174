import ResellerService from '@/services/ResellerService'

export default {
  state: {
    reseller: {},
    resellerProspect: {},
    resellers: [],
    prospectResellers: [],
    searchResellers: [],
    searchProspectResellers: [],
    total: null,
    totalProspect: null,
    totalRequest: null,
    totalRequestProspect: null,
  },
  mutations: {
    ADD_RESELLER(state, reseller) {
      state.resellers.push(reseller)
    },
    SET_RESELLERS(state, resellers) {
      state.resellers = resellers
    },
    SET_PROSPECT_RESELLERS(state, prospectResellers) {
      state.prospectResellers = prospectResellers
    },
    SET_SEARCH_RESELLERS(state, resellers) {
      state.searchResellers = resellers
    },
    SET_SEARCH_PROSPECT_RESELLERS(state, resellers) {
      state.searchProspectResellers = resellers
    },
    SET_RESELLERS_TOTAL(state, total) {
      state.total = total
    },
    SET_PROSPECT_RESELLERS_TOTAL(state, total) {
      state.totalProspect = total
    },
    SET_RESELLERS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_PROSPECT_RESELLERS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequestProspect = totalRequest
    },
    SET_RESELLER(state, reseller) {
      state.reseller = reseller
    },
    SET_PROSPECT_RESELLER(state, reseller) {
      state.resellerProspect = reseller
    },
  },
  actions: {
    /**
     * Fetch in state list of Resellers
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchResellers({ commit }, { perPage, page, order, search }) {
      return ResellerService.getResellers(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null,
        false
      )
        .then(response => {
          commit('SET_RESELLERS', response.data.list)
          commit('SET_RESELLERS_TOTAL', response.data.total)
          commit('SET_RESELLERS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on reseller list: ', error)
        })
    },
    fetchProspectResellers({ commit }, { perPage, page, order, search }) {
      return ResellerService.getResellers(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null,
        true
      )
        .then(response => {
          commit('SET_PROSPECT_RESELLERS', response.data.list)
          commit('SET_PROSPECT_RESELLERS_TOTAL', response.data.total)
          commit('SET_PROSPECT_RESELLERS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on reseller list: ', error)
        })
    },
    fetchSearchResellers({ commit }, search) {
      return ResellerService.getSearchResellers(search)
        .then((response) => {
          commit('SET_SEARCH_RESELLERS', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on search reseller list: ', error)
        })
    },
    /**
     * Fetch in state a Reseller
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchReseller({ commit }, id)
    {
      ResellerService.getReseller(id)
        .then(response => {
          commit('SET_RESELLER', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Reseller: ', error)
        })
    },
    /**
     * Create Reseller
     *
     * @param commit
     * @param reseller
     */
    createReseller({ commit }, reseller)
    {
      if (reseller.country && reseller.country.id) {
        reseller.country = reseller.country.id
      }
      return ResellerService.create(reseller)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_RESELLER', reseller)
          return Promise.resolve()
        })
    },
    /**
     * Update Reseller
     *
     * @param commit
     * @param id
     * @param reseller
     * @returns {*}
     */
    updateReseller({ commit }, {id, reseller})
    {
      if (reseller.country && reseller.country.id) {
        reseller.country = reseller.country.id
      }
      return ResellerService.edit(id, reseller)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_RESELLER', response.data)
        })
    },
    /**
     * Update Reseller
     *
     * @param commit
     * @param id
     * @param reseller
     * @returns {*}
     */
    updateIsActiveReseller({ commit }, {id, reseller})
    {
      return ResellerService.editIsActive(id, reseller)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_RESELLER', response.data)
        })
    },
    /**
     * Delete Reseller
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteReseller({ commit }, {id}) {
      return ResellerService.delete(id)
    }
  },
  getters: {
    /**
     * Get reseller by id in list Resellers
     *
     * @param state
     * @returns {function(*): *}
     */
    getResellerById: state => id => {
      return state.resellers.find(reseller => reseller.id === id)
    },
    getProspectResellerById: state => id => {
      return state.prospectResellers.find(reseller => reseller.id === id)
    },
    getResellers: state => {
      return state.resellers
    }
  }
}
